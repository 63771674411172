.skill-level {
	display: flex;
	margin: 2rem 0;
	.label-container {
		display: inherit;
		width: 100px;
		height: 1.5rem;
		justify-content: center;
		background-color: #1B2845;
		border-top-left-radius: 50px;
		border-bottom-left-radius: 50px;
		.label {
			display: inherit;
			align-items: center;
			margin: 0;
			font-size: .7rem;
			font-weight: 600;
			color: #fff;
		}
	}
	.progress {
		&.bar {
			width: calc(100% - 100px);
			height: 1.5rem;
			border-top-left-radius: 0px;
			border-bottom-left-radius: 0px;
			border-top-right-radius: 50px;
			border-bottom-right-radius: 50px;
			.progress-bar {
				// border-top-right-radius: 20px;
				// border-bottom-right-radius: 20px;
				transition: width 1.5s linear;
			}
		}
	}
}

@media only screen and (max-width: 600px) {
	.skill-level {
		margin: 1.4rem 0;
		.label-container {
			width: 80px;
			height: 1.3rem;
			.label {
				font-size: .6rem;
			}
		}
		.progress {
			&.bar {
				width: calc(100% - 80px);
				height: 1.3rem;
			}
		}
	}
}
