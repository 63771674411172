.error-wrapper {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-wrap: wrap;
	flex-direction: column;
	text-align: center;
	&.bg-blue {
		background-color: #26547C;
		padding: 2.5rem 5.5rem;
		.img {
			max-width: 460px;
			object-fit: cover;
			object-position: center;
		}
		.text {
			margin-top: 4rem;
			margin-bottom: 3rem;
			color: #fffcf9;
			font-size: 1rem;
			line-height: 2;
		}
		.btn.btn-back {
			padding: .5rem 1.5rem;
			background-color: #ef476f;
			border: 1px solid #ef476f;
			color: #fffcf9;
			font-size: 1rem;
			font-weight: 600;
			text-transform: capitalize;
			&:hover {
				background-color: #bf3858;
				border: 1px solid #bf3858;
			}
		}
	}
}

@media only screen and (max-width: 992px) {
	.error-wrapper {
		height: 100vh;
		&.bg-blue {
			padding: 1rem 2rem;
		}
	}
}

@media only screen and (max-width: 535px) {
	.error-wrapper {
		&.bg-blue {
			.img {
				max-width: 100%;
			}
		}
	}
}
