.indicator {
	margin-right: 3rem;
	.box {
		display: inline-block;
		width: .9rem;
		height: .9rem;
		margin-right: .6rem;
		border-radius: 2px;
	}
	.name {
		font-weight: 500;
		font-size: .9rem;
		color: #26547C;
	}
}

@media only screen and (max-width: 600px) {
	.indicator {
		margin-right: 1rem;
		.box {
			width: .8rem;
			height: .8rem;
			margin-right: .5rem;
		}
		.name {
			font-size: .75rem;
		}
	}
}
