.app {
	&.container-fluid {
		padding-left: 0;
		padding-right: 0;
		& > .row {
			position: relative;
			height: 100vh;
			margin-left: 0;
			margin-right: 0;
			overflow: hidden;
		}
	}
}

@media only screen and (max-width: 992px) {
	.app {
		&.container-fluid {
			& > .row {
				height: auto;
				overflow-x: auto;
				overflow-y: auto;
			}
		}
	}
}
