li.nav-item.navigation-item {
	padding: 10px 25px;

	a.nav-link.navigation-link {
		padding: 0;
		color: #FFFCF9;
		font-weight: 600;
		font-size: .9rem;
		transition: color .2s linear;

		&:hover {
			color: #FFD166;
		}

		&.active {
			color: #FFD166;
		}
	}
}

@media only screen and (max-width: 1200px) {
	li.nav-item.navigation-item {
		padding: 10px 15px;
	}
}
