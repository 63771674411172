.card {
	&.project-card {
		overflow: hidden;
		border: none;
		border-radius: 5px;
		background-color: transparent;
		.card-img-container {
			overflow: hidden;
		}
		.card-info {
			margin-top: 1rem;
			.company-name {
				margin: 0;
				text-align: center;
				color: #fff;
				font-size: .95rem;
				font-weight: 600;
			}
			.roles {
				margin: 0;
				text-align: center;
				color: rgba(255, 255, 255, 0.7);
				font-size: .7rem;
				font-weight: 600;
				font-style: italic;
			}
		}
	}
}
