.left-pane {
	height: 100%;
	&.bg-blue {
		background-color: #26547C;
		padding: 2.5rem 5.5rem;
		.logo {
			position: absolute;
			left: 0;
			height: 30px;
			width: 30px;
		}
		.container.illustration-container {
			height: calc(100% - 50px);
			.d-flex.illustration-box {
				height: 100%;
				max-width: 35rem;
				margin-left: auto;
				margin-right: auto;
				.image {
					width: 100%;
					object-fit: fit;
					object-position: center;
				}
			}
		}
	}
}

@media only screen and (max-width: 1200px) {
	.left-pane {
		&.bg-blue {
			padding: 1rem 2rem;
		}
	}
}

@media only screen and (max-width: 992px) {
	.left-pane {
		&.bg-blue {
			& > .container {
				max-width: 100%;
			}
			.container.illustration-container {
				height: 500px;
				.d-flex.illustration-box {
					max-width: 400px;
				}
			}
		}
	}
}

@media only screen and (max-width: 500px) {
	.left-pane {
		&.bg-blue {
			& > .container {
				padding-left: 0;
				padding-right: 0;
			}
			.container.illustration-container {
				height: 400px;
			}
		}
	}
}
