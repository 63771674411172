h1.header {
	font-size: 2rem;
	font-weight: 600;
	color: #EF476F;
	padding: 10px 0;
	margin-bottom: 1.8rem;
}

@media only screen and (max-width: 1200px) {
	h1.header {
		font-size: 1.7rem;
		margin-bottom: .8rem;
	}
}

@media only screen and (max-width: 600px) {
	h1.header {
		font-size: 1.4rem;
	}
}
