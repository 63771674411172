.social-button-wrapper {
	display: flex;
	width: 30px;
	height: 30px;
	margin: 0 15px;
	border-radius: 50%;
	overflow: hidden;
	.img {
		width: 100%;
		object-fit: cover;
		object-position: center;
	}
}
