.right-pane {
	position: relative;
	height: 100%;
	overflow-y: scroll;
	&.bg-light-white {
		padding: 2.5rem 5.5rem;
		background-color: #FFFCF9;
		.container {
			.intro-pitch {
				line-height: 2.4;
				font-size: .9rem;
				font-weight: 500;
				color: #26547C;
				margin-bottom: 2rem;
			}
			.skills-wrapper {
				margin-bottom: 3rem;
			}
			.indicators-wrapper {
				display: flex;
				margin-bottom: 3rem;
			}
			.project-card-wrapper {
				display: flex;
				margin: 1rem 0;
				.project-card-container {
					padding: 40px;
					border-radius: 5px;
					&:hover {
						cursor: pointer;
						transition: all .3s ease;
					}
					&:hover > .card-img-overlay.card-overlay-hover {
						opacity: .95;
					}
					.card-img-overlay.card-overlay-hover {
						padding: 0;
						overflow: hidden;
						opacity: 0;
						border-radius: 5px;
						transition: all .3s ease;
						&:hover {
							opacity: .95;
						}
						.card-body {
							display: flex;
							flex-flow: column;
							justify-content: center;
							align-items: center;
							padding: 0;
							.title {
								color: #fff;
								font-size: 1.4rem;
								font-weight: 600;
							}
							.tools {
								display: flex;
								flex-wrap: wrap;
								justify-content: center;
								text-align: center;
								margin-top: 1.2rem;
							}
						}
					}
				}
				&:nth-child(odd) {
					padding-left: 0;
				}
				&:nth-child(even) {
					padding-right: 0;
				}
			}
		}
	}
	// &::-webkit-scrollbar {
	// 	display: none;
	// }
}

@media only screen and (min-width: 992px) and (max-width: 1400px) {
	.right-pane {
		&.bg-light-white {
			padding: 1rem 2rem;
			.container {
				.intro-pitch {
					line-height: 2;
					font-size: .85rem;
					font-weight: 500;
					margin-bottom: 1rem;
				}
				.project-card-wrapper {
					.project-card-container {
						padding: 20px;
					}
				}
			}
		}
	}
}

@media only screen and (min-width: 992px) and (max-width: 1100px) {
	.right-pane {
		&.bg-light-white {
			.container {
				.project-card-wrapper {
					flex: 0 0 100%;
					max-width: 100%;
					.project-card-container {
						padding: 80px;
					}
					&:nth-child(even), &:nth-child(odd) {
						padding: 0;
					}
				}
			}
		}
	}
}

@media only screen and (max-width: 767px) {
	.right-pane {
		&.bg-light-white {
			.container {
				.project-card-wrapper {
					.project-card-container {
						padding: 50px;
					}
					&:nth-child(even), &:nth-child(odd) {
						padding: 0;
					}
				}
			}
		}
	}
}

@media only screen and (max-width: 600px) {
	.right-pane {
		&.bg-light-white {
			padding: 1rem;
			.container {
				.intro-pitch {
					font-size: .8rem;
					margin-bottom: 1rem;
				}
				.skills-wrapper {
					margin-bottom: 1rem;
				}
				.indicators-wrapper {
					margin-bottom: 1rem;
				}
				.project-card-wrapper {
					.project-card-container {
						padding: 30px;
					}
				}
			}
		}
	}
}
